import React from "react"
import { graphql } from "gatsby"
// import Layout from "@/components/layout"
import Moment from "moment"
import imageUrlBuilder from "@sanity/image-url"
import clientConfig from "../../client-config"
import PortableTextBlock from "../components/portableText"
import Seo from "../components/seo"

import { Helmet } from "react-helmet"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export const query = graphql`
  query PostTemplateQuery($id: String!) {
    post: sanityPost(id: { eq: $id }, _id: { glob: "!drafts*" }) {
      title
      slug {
        current
      }
      _createdAt
      _id
      code
      codeContent
      richContent
      _rawRich
      category {
        color
        title
      }
      excerpt
      date
      tags
      mainImage {
        asset {
          url
        }
      }
    }
  }
`

const PostTemplate = props => {
  const { data } = props
  const post = data && data.post

  const builder = imageUrlBuilder(clientConfig.sanity)
  function urlFor(source) {
    return builder.image(source)
  }

  return (
    <div className="bg-white dark:bg-zinc-800 text-zinc-800 dark:text-zinc-300  pb-10">
      <Seo
        title={post.title + " - Budmen"}
        description={post.excerpt}
        defer={false}
        image={urlFor(post.mainImage)
          .width(1200)
          .height(628)
          .auto("format")
          .url()}
        twitterImage={urlFor(post.mainImage)
          .width(1200)
          .height(628)
          .auto("format")
          .url()}
      />

      <section className="container px-2 mx-auto py-6 ">
        <div className="text-zinc-500 text-[0.625rem] mb-2">
          <AniLink
            className="text-zinc-400"
            to="/news"
            paintDrip
            duration={0.5}
            hex={"#15b8a6"}
          >
            News
          </AniLink>
          / {post.slug.current}
        </div>
        <h1 className="font-bold text-3xl mb-4">{post.title}</h1>
        {post.excerpt && <p>{post.excerpt}</p>}
        <div className="flex justify-between items-center text-sm">
          <span className="text-xs">
            {Moment(post.date).format("MMMM D, YYYY")}
          </span>
          <span
            style={{ background: post.category.color }}
            className="rounded-full text-xs text-white px-2"
          >
            {post.category.title}
          </span>
        </div>
      </section>
      <section className="container mx-auto">
        <img
          src={urlFor(post.mainImage)
            .width(1200)
            .height(800)
            .auto("format")
            .url()}
          alt={post.title}
        />
      </section>
      {post.codeContent === true && (
        <section className="container px-2 mx-auto ">
          <Helmet>
            <link
              rel="stylesheet"
              href="https://unpkg.com/tachyons@4/css/tachyons.min.css"
            />
          </Helmet>
          <div dangerouslySetInnerHTML={{ __html: post.code }} />
        </section>
      )}
      {post.richContent === true && (
        <section className="container px-2 mx-auto">
          <PortableTextBlock blocks={post._rawRich} />
        </section>
      )}
    </div>
  )
}

export default PostTemplate
